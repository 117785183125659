<template>
    <Criteria :filterTemplates = "filterTemplates"/>
    <div class="mt-2" v-if="filterTemplates.filterGroup">
        <textarea class="form-control rounded-0" :class="{'is-invalid':filterTemplates.filterGroup && filterTemplates.filterGroup.error}" 
            placeholder="Free text filter..." id="floatingTextareatemplate"  
            @focusout="focus" rows="4" 
            v-model = "filterTemplates.filterGroup.template"></textarea>
        <div class="invalid-feedback" v-if="filterTemplates.filterGroup">
            {{$t(filterTemplates.filterGroup.error)}}
        </div>
    </div>
   
</template>
<script setup>
    import Criteria from 'o365.vue.components.FilterTemplate.Criteria.vue';
    const props = defineProps({
        filterTemplates:Object
    });

     const saveAs = () =>{
        props.filterTemplates.primKey = null;
        props.filterTemplates.save().then(()=>{
            props.filterTemplates.dsFilterTemplates.load();

        })
    }
    const focus = () =>{
        props.filterTemplates.updateTemplate();

    }

</script>